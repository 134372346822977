exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-callback-haierlogin-js": () => import("./../../../src/pages/callback/haierlogin.js" /* webpackChunkName: "component---src-pages-callback-haierlogin-js" */),
  "component---src-pages-callback-index-js": () => import("./../../../src/pages/callback/index.js" /* webpackChunkName: "component---src-pages-callback-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-babel-prodotto-js": () => import("./../../../src/templates/babel-prodotto.js" /* webpackChunkName: "component---src-templates-babel-prodotto-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-compare-cross-line-js": () => import("./../../../src/templates/compare-cross-line.js" /* webpackChunkName: "component---src-templates-compare-cross-line-js" */),
  "component---src-templates-compare-js": () => import("./../../../src/templates/compare.js" /* webpackChunkName: "component---src-templates-compare-js" */),
  "component---src-templates-locale-registerbrand-js": () => import("./../../../src/templates/locale-registerbrand.js" /* webpackChunkName: "component---src-templates-locale-registerbrand-js" */),
  "component---src-templates-page-cross-line-js": () => import("./../../../src/templates/page-cross-line.js" /* webpackChunkName: "component---src-templates-page-cross-line-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-page-line-js": () => import("./../../../src/templates/page-line.js" /* webpackChunkName: "component---src-templates-page-line-js" */),
  "slice---src-components-footer-epp-js": () => import("./../../../src/components/footer-epp.js" /* webpackChunkName: "slice---src-components-footer-epp-js" */),
  "slice---src-components-footer-js": () => import("./../../../src/components/footer.js" /* webpackChunkName: "slice---src-components-footer-js" */),
  "slice---src-components-footer-simple-js": () => import("./../../../src/components/footer-simple.js" /* webpackChunkName: "slice---src-components-footer-simple-js" */),
  "slice---src-components-navigation-js": () => import("./../../../src/components/navigation.js" /* webpackChunkName: "slice---src-components-navigation-js" */),
  "slice---src-components-new-footer-js": () => import("./../../../src/components/new-footer.js" /* webpackChunkName: "slice---src-components-new-footer-js" */),
  "slice---src-components-new-footer-simple-js": () => import("./../../../src/components/new-footer-simple.js" /* webpackChunkName: "slice---src-components-new-footer-simple-js" */)
}

